import lottie from 'lottie-web';

export function animateProductCards() {
    const productCards = document.querySelectorAll(".product-card");
    const hasAnimatedProductCards = localStorage.getItem("hasAnimatedProductCards");

    if (hasAnimatedProductCards) {
        // If animations have already run, immediately apply the final state
        productCards.forEach((card) => card.classList.add("animate"));
    } else {
        const observerOptions = {
            root: null,
            threshold: 0.65, // Trigger early enough for smooth effect
        };

        const onIntersect = (entries, observer) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    const delay = entry.target.getAttribute("data-delay") || "0s";
                    entry.target.style.transitionDelay = delay;
                    entry.target.classList.add("animate");

                    // Unobserve the element after animating once
                    observer.unobserve(entry.target);
                }
            });
        };

        const observer = new IntersectionObserver(onIntersect, observerOptions);
        productCards.forEach((card) => observer.observe(card));

        // Mark as animated in localStorage to prevent repeat animations
        localStorage.setItem("hasAnimatedProductCards", "true");
    }
}


export function animateVirtueCards() {
    const virtueCards = document.querySelectorAll(".virtue-card");
    const hasAnimatedVirtueCards = localStorage.getItem("hasAnimatedVirtueCards");

    if (hasAnimatedVirtueCards) {
        // If animations have already run, set cards to the final animated state
        virtueCards.forEach((card) => card.classList.add("animate"));
    } else {
        const observerOptions = {
            root: null,
            threshold: 0.75
        };

        const onIntersect = (entries, observer) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    const delay = entry.target.getAttribute("data-delay") || "0s";
                    entry.target.style.transitionDelay = delay;
                    entry.target.classList.add("animate");

                    // Unobserve the element after animating once
                    observer.unobserve(entry.target);
                }
            });
        };

        // Create an Intersection Observer instance
        const observer = new IntersectionObserver(onIntersect, observerOptions);

        // Observe each card
        virtueCards.forEach((card) => observer.observe(card));

        // Set a flag in localStorage to prevent re-running animations on future visits
        localStorage.setItem("hasAnimatedVirtueCards", "true");
    }
}


export function initializeLottieAnimations() {
  const hasAnimatedLottie = localStorage.getItem("hasAnimatedLottie");

  const leftAnimationElements = document.querySelectorAll(".animation-left");
  const rightAnimationElements = document.querySelectorAll(".animation-right");

  // Observer options
  const observerOptions = { root: null, threshold: 0.85 };

  const playAnimations = (entries, observer) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        const animationPath = entry.target.classList.contains("animation-left")
          ? "/json/left-colombian.json"
          : "/json/right-colombian.json";

        const animationInstance = lottie.loadAnimation({
          container: entry.target,
          renderer: "svg",
          loop: false,
          autoplay: true,
          path: animationPath,
        });

        // Event listener to stop at the final frame after playing
        animationInstance.addEventListener("complete", () => {
          const totalFrames = animationInstance.totalFrames;
          animationInstance.goToAndStop(totalFrames - 1, true); // Freeze at last frame

          // Store a reference to indicate animation has played
          localStorage.setItem("hasAnimatedLottie", "true");
          localStorage.setItem(
            `lottie_${entry.target.className}`,
            JSON.stringify({ lastFrame: totalFrames - 1 })
          );
        });

        observer.unobserve(entry.target);
      }
    });
  };

  // Check if animations have already run by verifying localStorage
  if (hasAnimatedLottie) {
    // Apply last frame directly if animations have already run
    leftAnimationElements.forEach((el) => {
      const storedData = localStorage.getItem(`lottie_${el.className}`);
      if (storedData) {
        const { lastFrame } = JSON.parse(storedData);
        const animationInstance = lottie.loadAnimation({
          container: el,
          renderer: "svg",
          loop: false,
          autoplay: false,
          path: "/json/left-colombian.json",
        });
        animationInstance.goToAndStop(lastFrame, true);
      }
    });

    rightAnimationElements.forEach((el) => {
      const storedData = localStorage.getItem(`lottie_${el.className}`);
      if (storedData) {
        const { lastFrame } = JSON.parse(storedData);
        const animationInstance = lottie.loadAnimation({
          container: el,
          renderer: "svg",
          loop: false,
          autoplay: false,
          path: "/json/right-colombian.json",
        });
        animationInstance.goToAndStop(lastFrame, true);
      }
    });
  } else {
    // Run the animations if they haven't already been played
    const observer = new IntersectionObserver(playAnimations, observerOptions);

    leftAnimationElements.forEach((el) => observer.observe(el));
    rightAnimationElements.forEach((el) => observer.observe(el));
  }
}

// animation-control.js

export function animateImagesOnScroll() {
  const images = document.querySelectorAll(".scroll-animate");

  const observerOptions = {
    root: null,
    threshold: 0.35
  };

  const observer = new IntersectionObserver((entries, observer) => {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        entry.target.classList.add("slide-up");
        observer.unobserve(entry.target); // Stop observing once animated
      }
    });
  }, observerOptions);

  images.forEach(img => observer.observe(img));
}