import { createRouter, createWebHistory } from 'vue-router';
import HomePage from '../components/HomePage.vue';
import AboutPage from '../components/AboutPage.vue';
import FindUs from '../components/FindUs.vue';
import OurProducts from "@/components/OurProducts.vue";
import LoginPage from "@/components/LoginPage.vue";
import CartView from "@/components/CartView.vue";
import SearchResults from "@/components/SearchResults.vue";
import MyCheckout from "@/components/MyCheckout.vue";
import OrderSuccess from "@/components/OrderSuccess.vue";

const routes = [
  {
    path: '/',
    component: HomePage,
    meta: { title: 'Delicias Del Pan - Artisanal Bread' }
  },
  {
    path: '/about',
    component: AboutPage,
    meta: { title: 'About Us' }
  },
  {
    path: '/findUs',
    component: FindUs,
    meta: { title: 'Find Us' }
  },
  {
    path: '/products',
    component: OurProducts,
    meta: { title: 'Our Products' }
  },
  {
    path: '/login',
    component: LoginPage,
    meta: { title: 'Login' }
  },
  {
    path: '/cart',
    component: CartView,
    meta: { title: 'Your Cart' }
  },
  {
    path: '/search',
    name: 'SearchResults',
    component: SearchResults,
    meta: { title: 'Search Results' }
  },
  {
    path: '/checkout',
    name: 'checkout',
    component: MyCheckout,
    meta: { title: 'Checkout' }
  },

  {
    path: '/order-success',
    name: 'order-success',
    component: OrderSuccess,
    meta: {title: 'Order Success'}

  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior() {
    return { top: 0 }; // Scroll to top on route change
  }
});

// Global Navigation Guard for Page Titles
router.afterEach((to) => {
  const defaultTitle = 'Delicias del Pan Bakery'; // Fallback title
  document.title = to.meta.title || defaultTitle;
});

export default router;