<template>
  <div id="app" class="flex flex-col min-h-screen">
    <!-- Navbar -->
    <div v-if="!isCheckoutPage">
      <MainNavbar/>
    </div>

    <div v-else>
      <div class="branding py-0 px-2">
        <div class="flex items-center space-x-2">
          <router-link to="/">
            <img src="/icons/logo.png" alt="Brand Logo" class="h-32 w-auto inline-block"/>
          </router-link>
          <h1 class="text-3xl sm:text-3xl lg:text-5xl text-white font-bold"
              style="font-family: 'Charter';">Checkout
          </h1>
        </div>

        <button
            @click="toggleCart"
            class="absolute top-12 right-10 p-2 rounded-full"

        >
          <img src="/icons/basket.png" alt="Basket" class="h-12 w-12 object-contain"/>
        </button>
        <!-- Basket Icon with Shake Animation -->

        <!-- Cart Count -->
        <span
            v-if="cartItemCount > 0"
            class="text-lg inline-flex items-center justify-center w-8 h-8 bg-blue-400 rounded-full text-white font-bold -mt-8"
        >
    {{ cartItemCount }}
  </span>
      </div>
    </div>

    <!-- Cart Sidebar -->
    <!-- Cart Sidebar and Backdrop -->
    <transition name="slide-in">
      <div v-if="isCartOpen" class="fixed inset-0 z-50">
        <!-- Backdrop -->
        <div
            class="absolute inset-0 bg-black bg-opacity-10"
            @click="toggleCart"
        ></div>

        <!-- Cart Window -->
        <div class="flex flex-col absolute top-0 right-0 h-full w-3/4 sm:w-3/4 md:w-2/3 lg:w-1/3 xl:w-1/3 bg-[#8B4513] shadow-lg p-4 z-60 overflow-y-scroll pt-12">
          <div class="flex items-center pb-10">
            <router-link to="/cart" @click="toggleCart"
                         class="text-xl font-medium text-white underline"
                         style="font-family: 'Lora', serif;">
              Edit Cart
            </router-link>

            <button @click="toggleCart"
                class="w-8 h-8 font-bold bg-white text-xl text-black font-bold rounded-full shadow-md ml-auto">
              ✕
            </button>
          </div>
          <!-- Cart Items -->
          <div v-for="item in cartItems" :key="item.id"
               class="flex justify-between py-3">

            <div class="container p-2">

              <div class="flex items-center">

                <div class="">

                  <img :src="item.image" :alt="item.name"
                       class="w-32 h-32 object-center"/>

                </div>

                <div class="space-y-3 ml-12">
                  <h1 class="text-2xl font-bold text-white ml-auto">{{ item.name }}</h1>
                  <h1 class="text-2xl font-bold text-white ml-auto">x {{ item.quantity }} @
                    ${{ item.price }}</h1>

                </div>

              </div>

              <div class="border-b-2 border-white w-3/4 pt-8 mx-auto"></div>
            </div>

          </div>
          <!-- Totals -->
          <div class="mt-auto border-t pt-4">
            <p class="flex">
              <span class="font-bold">Total:</span> <span>&nbsp;${{
                cartTotal.toFixed(2)
              }}</span>
            </p>


            <p class="text-center text-white text-lg"
               style="font-family: 'Pacifico', cursive;">@Delicias Del
              Pan</p>
          </div>
          <!-- Close Button -->
        </div>
      </div>
    </transition>

    <!-- Main content area -->
    <main class="flex-grow">
      <router-view/>
    </main>

    <!-- Footer (outside main content wrapper) -->
    <footer class="text-white py-5 w-full footer-bar">
      <div class="container mx-auto px-3 lg:px-4">
        <!-- Footer Content Wrapper -->
        <div class="grid grid-cols-1 md:grid-cols-3 gap-8">
          <!-- Contact Info -->
          <div class="text-center md:text-left text-white">
            <h3 class="text-xl font-semibold mb-4">Contact Us</h3>
            <p><strong>Hours Of Operation:</strong> 8am - 6pm</p>
            <p><strong>Phone:</strong> 626-747-1369</p>
            <p><strong>Email:</strong> deliciasdelpanbakery@gmail.com</p>
          </div>
          <!-- Quick Links -->
          <div class="text-center text-white">
            <h3 class="text-xl font-semibold mb-4">Quick Links</h3>
            <ul class="space-y-2">
              <li>
                <router-link to="/" class="hover:underline">Home</router-link>
              </li>
              <li>
                <router-link to="/products" class="hover:underline">Menu</router-link>
              </li>
              <li>
                <router-link to="/about" class="hover:underline">About Us</router-link>
              </li>
              <li>
                <router-link to="/findUs" class="hover:underline">Find Us</router-link>
              </li>
            </ul>
          </div>
          <!-- Social Media Links -->
          <div class="text-center md:text-right text-white">
            <h3 class="text-xl font-semibold mb-4">Follow Us</h3>
            <div class="flex justify-center md:justify-end space-x-4">
              <a href="https://www.facebook.com/profile.php?id=61551020559160" target="_blank" class="hover:text-gray-100"><img src="/icons/facebook.png"
                                                           alt="Facebook"></a>
              <a href="https://www.instagram.com/deliciasdelpanbakery/?fbclid=IwY2xjawHALxxleHRuA2FlbQIxMAABHf_h5AzxJ3gKKtglLLEfqDWOpQ2DO_PDqdHtzJ1MlfhGXBCmrAdFP2uZhw_aem_GMukrTMD4_i5RpROkOkOBw" target="_blank" class="hover:text-gray-100"><img src="/icons/instagram.png"
                                                           alt="Instagram"></a>
              <a href="https://www.facebook.com/profile.php?id=61551020559160" target="_blank" class="hover:text-gray-100"><img src="/icons/google.png"
                                                           alt="Google"></a>
            </div>
          </div>
        </div>

        <!-- Footer Bottom -->
        <div class="mt-6 pt-3 text-center text-gray-500 text-sm border-t-2 border-white">
          <p>© 2024 Delicias Del Pan. All rights reserved.</p>
        </div>
        <div class="text-center flex mt-2">
          <p class="ml-auto text-sm text-white">Powered by <a href="https://codebyjuan.com"
                                                              target="_blank"
                                                              class="font-bold">KO'de By
            Juan</a></p>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import MainNavbar from './components/MainNavbar.vue';
import {mapState} from "vuex";

export default {
  data() {

    return {
      isCartOpen: false,
    }
  },

  methods: {
    toggleCart() {
      this.isCartOpen = !this.isCartOpen;
    }

  },

  computed: {
    ...mapState({
      cartItems: (state) => state.cart, // Access cart contents from Vuex
    }),

    cartTotal() {
      return this.cartItems.reduce((total, item) => total + item.price * item.quantity, 0);
    },

    isCheckoutPage() {

      return this.$route.name === 'checkout';
    },

    cartItemCount() {
      return this.$store.getters.cartItemCount; //Vuex getter for total items in cart.
    }

  },
  name: 'App',
  components: {
    MainNavbar
  }
};
</script>

<style>
body {
  @import url('https://fonts.googleapis.com/css2?family=Pacifico&display=swap');
  @import url('https://fonts.googleapis.com/css2?family=Lora:ital,wght@0,400..700;1,400..700&family=Pacifico&display=swap');

  background-image: url("../public/images/background.jpeg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

p {
  color: white;
}

h1 {
  color: white;
  font-family: 'Lora', serif;
}

.footer-bar {
  background-color: #8B4513;
  color: white;
  padding: 30px 0;
  text-align: center;
}

#app {
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Ensure full viewport height for flex layout */
}

main {
  flex-grow: 1; /* Pushes footer to the bottom when content is short */
}

/* Add to main.css or <style> in App.vue */
.splide__pagination__page {
  background: #ccc !important;
  width: 12px !important;
  height: 12px !important;
  border-radius: 100% !important;
  margin: 5px !important;
  opacity: 0.7 !important;
  transition: transform 0.2s linear !important;
  bottom: -5vh !important;
}

.splide__pagination__page.is-active {
  background: saddlebrown !important;
  transform: scale(1.3) !important;
}

.splide__arrow {
  background-color: rgba(10, 10, 10, 0.2) !important; /* Dark semi-transparent background */
  color: #fff !important; /* White arrow color */
  border-radius: 5% !important; /* Rounded arrow background */
  padding: 15px !important; /* Increase padding for larger arrows */
  font-size: 2.5rem !important; /* Increase arrow icon size */
  display: flex;
  align-items: center;
  justify-content: center;
  top: 50% !important;
  transform: translateY(-50%) !important; /* Center vertically */
  cursor: pointer;
  transition: background-color 0.3s ease; /* Smooth hover effect */

}

.splide__arrow:hover {
  background-color: rgba(0, 0, 0, 0.9) !important; /* Darker on hover */
}

.splide__arrow--prev {
  left: -55px !important; /* Move left arrow further left */
}

.splide__arrow--next {
  right: -55px !important; /* Move right arrow further right */
}

.branding {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  border-bottom: 1px solid #ddd;
  background-color: #8B4513;
}

.return-to-cart {
  text-decoration: none;
  color: #007bff;
  font-size: 16px;
}

.return-to-cart:hover {
  text-decoration: underline;
}

/* Smooth sliding transition */
.slide-in-enter-active,
.slide-in-leave-active {
  transition: transform 0.4s ease-in-out, opacity 0.4s ease-in-out;
}

.slide-in-enter {
  transform: translateX(100%); /* Start off-screen */
  opacity: 0;
}

.slide-in-enter-to {
  transform: translateX(0); /* Slide into view */
  opacity: 1;
}

.slide-in-leave {
  transform: translateX(0); /* Start at current position */
  opacity: 1;
}

.slide-in-leave-to {
  transform: translateX(100%); /* Slide out */
  opacity: 0;
}

span {
  font-family: 'Lora', serif;
}

</style>


