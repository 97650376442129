<template>
  <div class="container mt-36 mx-auto p-4">
    <h2 class="text-5xl font-bold text-brown-700">Search Results for "{{ query }}"</h2>
    <div v-if="loading">Loading...</div>
    <div v-if="!loading && results.length === 0">No results found.</div>
    <div
        class="grid above-two-cols:grid-cols-4 two-cols:grid-cols-2 one-col: grid-cols-1 gap-16
    gap-16 mt-12 p-24 one-col:p--24 two-cols:p-0 lg:p-10 xl:p-0">
      <div v-for="product in results" :key="product.id"
           class="product-card transition transform duration-400 hover:-translate-y-3"
           @click="openAnimatedModal(product)"
           :class="{animateCard: isAnimating && selectedProduct?.id === product.id}">

        <img :src="product.image" :alt="product.name"
             class="w-full h-56 object-center mb-4"/>
        <h3 class="text-2xl font-semibold text-left text-black p-0"
            style="font-family: 'Lora', cursive;">{{
            product.name
          }}</h3>
      </div>
    </div>
  </div>

  <!-- Modal with transition -->

  <transition name="flip-scale">
    <div v-show="isModalOpen" class="modal-overlay" @click.self="closeModal">
      <div class="modal-content p-8 bg-brown-700 rounded-md pb-22">

        <h2 class="text-center text-white text-5xl font-bold mb-12" style="font-family:
      'Lora', cursive;">{{ selectedProduct?.name }}
        </h2>

        <!-- create a two column grid for the image and the description use flexbox alignment
        to center content -->

        <div
            class="grid col-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 items-center mb-12">

          <img :src="selectedProduct?.image" class="h-96 w-full">
          <p class="text-2xl font-bold text-left p-12">{{ selectedProduct?.description }}</p>

        </div>

        <!-- create a flexbox for the item quantity defaults to 1 and add to cart button -->
        <div class="flex items-center space-x-4 justify-center">
          <!-- Prevent negative values and ensure a minimum quantity of 1 -->
          <input step="any" v-model="quantity" type="number" id="quantity-input"
                 placeholder="1"
                 class="block w-1/6 p-4 text-gray-900 border border-gray-300 rounded-lg bg-gray-50 text-2xl no-step"/>
          <button @click="addToCart"
                  class="bg-amber-400 px-5 py-3 rounded-md text-white text-3xl font-bold"
                  style="font-family: 'Lora', cursive;">Add To Cart
          </button>
        </div>

        <p class="text-3xl text-white pt-16"
           style="font-family: 'Pacifico', cusrive">Delicias Del Pan Bakery&#8482;
        </p>

      </div>
    </div>
  </transition>
</template>

<script>
export default {
  data() {
    return {
      results: [],
      loading: false,
      query: '',
      isModalOpen: false,
      isAnimating: false,
      selectedProduct: null,
      quantity: 1, // initial quantity of the product.
    };
  },
  watch: {
    '$route.query.q': 'fetchResults', // Watches for changes in query parameter 'q' in the URL
  },
  methods: {
    openAnimatedModal(product) {
      this.isAnimating = true;
      this.selectedProduct = product;
      this.quantity = 1; //reset the quantity back to 1.
      // Allow the card animation to complete before opening the modal.
      setTimeout(() => {
        this.isModalOpen = true;
        setTimeout(() => {
          this.isAnimating = false;
        }, 500);
      }, 100);
    },


    closeModal() {
      this.isModalOpen = false;
      this.selectedProduct = null;
    },

    addToCart() {
      console.log('Selected Product in addToCart:', this.selectedProduct); // Log before any checks

      if (!this.selectedProduct || !this.selectedProduct.id) {
        alert('Product is invalid');
        return;
      }

      if (this.quantity <= 0) {
        alert('Quantity must be greater than 0');
        return;
      }
      // Dispatch to Vuex (assuming you've set up Vuex for cart management)
      this.$store.dispatch('addToCart', {
        product: this.selectedProduct,
        quantity: this.quantity,
      });

      this.closeModal(); // Close the modal after adding to cart

    },

    async fetchResults() {
      this.query = this.$route.query.q;
      if (!this.query) return;

      this.loading = true;
      try {
        // Full URL to the Django API running on localhost
        const response = await
            fetch(`https://www.deliciasdelpanadmin.com/api/products/search?q=${encodeURIComponent(this.query)}`);
        const data = await response.json();
        this.results = data;
      } catch (error) {
        console.error('Error fetching search results:', error);
        this.results = [];
      } finally {
        this.loading = false;
      }
    },
  },
  mounted() {
    this.fetchResults();
  },
};
</script>

<style>

/* Base styling for filter items */
/* Transition for opening and closing the mobile menu */


.product-card {
  overflow: hidden;
  transition: transform 0.5s ease; /* Add ease for smoother effect */
}

.animateCard {
  transform: rotateY(180deg) scale(1.5); /* Rotate and scale up */
  transition: transform 0.5s ease;
}

/* Chrome, Safari, Edge, Opera */
.no-step::-webkit-outer-spin-button,
.no-step::-webkit-inner-spin-button {
  -webkit-appearance: none; /* Hides the stepper controls */
  margin: 0; /* Removes extra margin added by some browsers */
}

/* Firefox */
.no-step {
  -moz-appearance: textfield; /* Hides the stepper controls */
}

/* Modal Overlay with Smooth Fade */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.8);
  backdrop-filter: blur(10px);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  opacity: 1;
  transition: opacity 0.3s ease;
}

.flip-scale-leave-active .modal-overlay {
  opacity: 0;
}

/* Modal Content */
.modal-content {
  width: 100%;
  max-width: 1210px;
  text-align: center;
  transform: scale(0.7);
  transition: transform 1.5s ease, opacity 0.3s ease;
  opacity: 1;
}

.flip-scale-enter-active, .flip-scale-leave-active {
  transition: transform 0.5s ease, opacity 0.3s ease;
}

.flip-scale-enter {
  transform: scale(0.5) rotateY(0deg);
  opacity: 0;
}

.flip-scale-enter-active {
  opacity: 1;
}

.flip-scale-leave-to {
  transform: scale(0.5) rotateY(180deg);
  opacity: 0;
}


/* Scale-Rotate Transition */
.flip-scale-enter-active, .flip-scale-leave-active {
  transition: transform 0.5s ease, opacity 0.3s ease;
}

.flip-scale-enter {
  transform: scale(0.5) rotateY(0deg);
  opacity: 0; /* Start content faded out */
}

.flip-scale-enter-active {
  opacity: 1; /* Fade in content smoothly */
}

.flip-scale-leave-to {
  transform: scale(0.5) rotateY(180deg);
  opacity: 0; /* Fade out content smoothly */
}

#heading {
  font-family: 'Pacifico', cursive;
}

.filter-item {
  transition: color 0.3s, transform 0.3s;
}

/* Hover and active styles */
.filter-item:hover {
  color: #8b4513; /* Custom brown color for hover */
  transform: translateY(20px); /* Slight scale-up on hover */
}

.active {
  color: #8b4513; /* Custom brown color for active filter */
  font-weight: bold;
  text-decoration: underline;
  background-color: brown;
}
</style>