<template>
  <div class="container mx-auto p-8 mt-40">
    <h1 class="text-6xl text-brown-700 font-bold mb-8"
        style="font-family: 'Lora', serif;">Your Cart</h1>

    <!-- If Cart is Empty -->
    <div v-if="cartItems.length === 0" class="text-center text-xl text-black font-bold">
      <p class="text-xl text-black font-bold">Your cart is empty.</p>
      <router-link to="/products" class="text-black underline hover:underline">Go back to
        shop
      </router-link>
    </div>

    <!-- Cart Items -->
    <div v-else>
      <div class="container p-4">
        <div class="grid above-two-cols:grid-cols-4 two-cols:grid-cols-2 one-col:grid-cols-1
    gap-14 p-10 sm:p-20 md:p-10 lg:p-12 xl:p-6
    m-2">
          <div v-for="item in cartItems" :key="item.id">
            <div class="product-card relative p-4">

              <button
                  style=""
                  @click="removeItem(item.id)"
                  class="absolute top-1.5 left-1.5">
                <span
                    class="text-lg inline-flex items-center justify-center w-9 h-9 bg-red-600 hover:bg-red-700 rounded-full text-white font-bold -mt-8">
                  x
                </span>

              </button>

              <img src="/images/decorative/heading.jpg" class="h-12 w-full">

              <img :src="item.image" :alt="item.name"
                   class="w-full h-52 object-center"/>

              <img src="/images/decorative/heading.jpg" class="h-12 w-full">

              <div class="flex items-center pt-6 p-3">
                <p class="text-2xl font-bold text-black text-left p-0" style="font-family:
              'Lora', serif;">
                  {{ item.name }}</p>
                <p class="text-2xl font-bold text-black p-0 ml-auto" style="font-family:
                'Lora', serif;">
                  ${{ item.price }}</p>
              </div>


            </div>

            <div class="flex items-center justify-center mt-3">

              <div
                  class="inline-flex items-center justify-center border-1 border-gray-300 overflow-hidden">
                <!-- Minus Button -->
                <button
                    @click="decreaseQuantity(item)"
                    class=" text-black px-3.5 py-1.5 bg-white border-2 border-gray-300 rounded-md font-bold">
                  -
                </button>

                <!-- Quantity Input -->
                <input
                    type="number"
                    v-model.number="item.quantity"
                    min="1"
                    class="w-16 px-3.5 py-1.5  text-center no-step"
                    @change="updateQuantity(item)"/>

                <!-- Plus Button -->
                <button
                    @click="increaseQuantity(item)"
                    class="bg-white text-black px-3.5 py-1.5 border-2 border-gray-300 font-bold rounded-md">
                  +
                </button>
              </div>
            </div>

          </div>
        </div>
      </div>

      <!-- Cart Total -->
      <div class="mt-8 mr-12 text-right text-4xl text-brown-700 font-bold" style="font-family:
      'Lora', serif;">
        Your Total: ${{ cartTotal.toFixed(2) }}
      </div>

      <!-- Checkout Button -->
      <div class="mt-8 space-x-8 flex items-center justify-center">
        <router-link
            class="bg-brown-600 p-2 text-white px-8 py-3 rounded-md text-xl font-bold lg:ml-auto"
            to="/products" style="font-family: 'Lora', serif;">
          Continue Shopping
        </router-link>

        <router-link
            to="/checkout"
            class="bg-brown-600 p-2 text-white px-8 py-3 rounded-md text-xl font-bold lg:ml-auto"
            @click="checkout" style="font-family: 'Lora', serif;">
          Checkout
        </router-link>

      </div>
    </div>
  </div>
</template>

<script>

export default {
  computed: {
    // Retrieve cart items from Vuex
    cartItems() {
      return this.$store.state.cart;
    },
    // Calculate the total price of the cart
    cartTotal() {
      return this.cartItems.reduce((total, item) => total + item.price * item.quantity, 0);
    },
  },
  methods: {

    // Increase Quantity
    increaseQuantity(item) {
      item.quantity++; // Increment the quantity
      this.updateQuantity(item); // Call update logic if needed
    },

    // Decrease Quantity
    decreaseQuantity(item) {
      if (item.quantity > 1) {
        item.quantity--; // Decrement the quantity only if it's greater than 1
        this.updateQuantity(item); // Call update logic if needed
      }
    },

    // Update the quantity of an item
    updateQuantity(item) {

      if (item.quantity < 0 || isNaN(item.quantity)) {
        alert("Negative values are not accepted!");
        item.quantity = 1;
      } else {
        this.$store.dispatch('updateCartItem', item);
      }
    },
    // Remove an item from the cart
    removeItem(productId) {
      this.$store.dispatch('removeFromCart', productId); // Dispatch the remove action
    },
    // Proceed to checkout
    checkout() {

    },
  },
};

</script>

<style scoped>
/* Chrome, Safari, Edge, Opera */
.no-step::-webkit-outer-spin-button,
.no-step::-webkit-inner-spin-button {
  -webkit-appearance: none; /* Hides the stepper controls */
  margin: 0; /* Removes extra margin added by some browsers */
}

/* Firefox */
.no-step {
  -moz-appearance: textfield; /* Hides the stepper controls */
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
  padding: 10px;
  border-bottom: 1px solid #ddd;
}

</style>