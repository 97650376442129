<template>
  <div class="container mx-auto p-8 mt-8">


    <!-- If Cart is Empty -->
    <div v-if="cartItems.length === 0" class="text-center text-xl text-black font-bold">
      <p>Your cart is empty.
        <router-link to="/products" class="underline">Go back to shop</router-link>
      </p>
    </div>

    <!-- Checkout Summary -->
    <div v-else>
      <h1 class="text-5xl text-center font-bold text-brown-700"
          style="font-family: 'Lora', serif;">One
        Click Away
        From
        Delicias.</h1>


      <!-- display a form to get personal information from the user.-->
      <div class="container w-full pt-12">

        <form
            class="flex-col w-3/4 mx-auto"
            id="personal_information">
          <h1 class="text-xl text-brown-700 font-bold">Personal Information</h1>

          <input type="text" id="first-name" v-model="firstName" required
                 class="text mt-5 block p-2 rounded-md px-2 w-full bg-gray-50"
                 placeholder="First Name">

          <input type="text" id="last-name" v-model="lastName" required
                 class="text mt-5 block p-2 rounded-md px-2 w-full bg-gray-50"
                 placeholder="Last Name">

          <input type="email" id="email" v-model="email" required placeholder="Email"
                 class="mt-5 block text p-2 rounded-md px-2 w-full bg-gray-50">

          <input type="text" id="phone" v-model="phone" required placeholder="Phone Number"
                 class="text mt-5 block p-2 rounded-md px-2 w-full bg-gray-50 w-full">
        </form>

      </div>


      <div id="payment-form" class="space-y-2 mt-12 w-3/4 mx-auto">
        <h1 class="text-xl text-brown-700 font-bold">Payment Details</h1>
        <div class="flex items-center space-x-2" id="accepted-cards">
          <h1 class="text-lg font-bold text-black opacity-75">Accepting:</h1>
          <img src="/icons/cards/amex.png">
          <img src="/icons/cards/mastercard.png">
          <img src="/icons/cards/discover.png">
          <img src="/icons/cards/visa.png">
        </div>
        <div class="card-number-wrapper relative flex items-center w-full">

          <div id="card-number"
               class="stripe-input p-2 border rounded-md bg-gray-50 w-full"></div>
        </div>
        <div class="flex items-center space-x-8 w-full">
          <div id="card-expiry"
               class="stripe-input p-2 border rounded-md bg-gray-50 w-32"></div>

          <div class="relative flex items-center">

            <div id="card-cvc"
                 class="stripe-input p-2 pl-2 border rounded-md bg-gray-50 w-32"></div>

            <img src="/icons/cards/cvc.png" class="absolute right-1 p-2 opacity-75">

          </div>
        </div>

      </div>

      <!-- Checkout Button -->
      <div class="flex text-center mt-8 justify-center items-center w-full">
        <button
            class="bg-brown-600 text-white px-8 py-3 rounded-md text-xl font-bold flex items-center justify-center"
            @click="processPayment"
            :disabled="isProcessing"
        >
          <span v-if="!isProcessing">Pay ${{ cartTotal.toFixed(2) }}</span>
          <span v-else>
    Processing<span class="dots"></span>
  </span>
        </button>
      </div>
    </div>
  </div>

  <div class="container p-0 -mt-28">
    <img src="/images/affiliations/stripe.png.svg"
         class="w-56 h-56 object-fill"/>
  </div>
</template>


<script>
import {mapState} from "vuex";
import {loadStripe} from "@stripe/stripe-js";
import api from "../api";

export default {
  data() {
    return {
      isProcessing: false,
      stripe: null,
      cardNumberElement: null,
      cardExpiryElement: null,
      cardCvcElement: null,
      firstName: "",
      lastName: "",
      phone: "",
      email: "",
    };
  },
  computed: {
    ...mapState({
      cartItems: (state) => state.cart, // Access cart contents from Vuex
    }),
    cartTotal() {
      // Calculate total price of the cart
      return this.cartItems.reduce(
          (total, item) => total + item.price * item.quantity,
          0
      );
    },
  },
  async mounted() {
    try {
      // Load Stripe.js and initialize elements
      this.stripe = await loadStripe(
          "pk_live_51QOUJdLiJKRH2AxNlZvusA2Ef8BYCBtH4j3RvifLufakb5AyggDWk3jEexwKKt8TEOpyjmQXxpo88QYXrReUzmd5003xIAc7sP"
      );
      const elements = this.stripe.elements();

      // Card Number Element
      this.cardNumberElement = elements.create("cardNumber", {
        style: {
          base: {
            fontSize: "16px",
            color: "#32325d",
            "::placeholder": {
              color: "#aab7c4",
            },
          },
          invalid: {
            color: "#fa755a",
            iconColor: "#fa755a",
          },
        },
        showIcon: true,
        iconStyle: "default",
        showPostalCode: true, // Enable zip/postal code field
      });
      this.cardNumberElement.mount("#card-number");

      // Expiration Date Element
      this.cardExpiryElement = elements.create("cardExpiry", {
        style: {
          base: {
            fontSize: "16px",
            color: "#32325d",
            "::placeholder": {
              color: "#aab7c4",
            },
          },
          invalid: {
            color: "#fa755a",
            iconColor: "#fa755a",
          },
        },
        showIcon: true,
        iconStyle: "default",
      });
      this.cardExpiryElement.mount("#card-expiry");

      // CVC Element
      this.cardCvcElement = elements.create("cardCvc", {
        style: {
          base: {
            fontSize: "16px",
            color: "#32325d",
            "::placeholder": {
              color: "#aab7c4",
            },
          },
          invalid: {
            color: "#fa755a",
            iconColor: "#fa755a",
          },
        },
      });
      this.cardCvcElement.mount("#card-cvc");
    } catch (error) {
      console.error("Error initializing Stripe:", error);
      alert("Failed to initialize payment processing. Please refresh the page.");
    }
  },
  methods: {
    async processPayment() {
      this.isProcessing = true;

      try {
        // Step 1: Create Payment Intent
        const payload = {
          first_name: this.firstName,
          last_name: this.lastName,
          email: this.email,
          phone: this.phone,
          total_amount: this.cartTotal, // Total cart amount
          currency: "usd", // Currency for payment
          cart_items: this.cartItems.map((item) => ({
            product_id: item.id, // Product ID
            quantity: item.quantity, // Quantity of the item
            price: item.price, // Price per item
          })),
        };

        //console.log("Sending payload to create-payment-intent:", payload);

        const response = await api.post("/create-payment-intent/", payload);
        const clientSecret = response.data.client_secret;
        //get the order number from metadata sent from backend.
        const orderNumber = response.data.order_number;
       // console.log("Here is the order number we recieved from backend.", orderNumber)

        // Step 2: Confirm Payment with Stripe
        const result = await this.stripe.confirmCardPayment(clientSecret, {
          payment_method: {
            card: this.cardNumberElement, // Pass card element
            billing_details: {
              name: `${this.firstName} ${this.lastName}`,
              email: this.email,
              phone: this.phone,
            },
          },
        });

        if (result.error) {
          // Handle payment failure
          console.error("Payment failed:", result.error.message);
          alert("Payment failed: " + result.error.message);
        } else if (result.paymentIntent.status === "succeeded") {
          // Payment succeeded
         // console.log("Payment successful:", result.paymentIntent);
          //alert("Payment successful!");

          // Clear cart and redirect
          this.$store.commit("clearCart"); // Clear Vuex cart
          localStorage.removeItem("cart"); // Clear cart from local storage
          this.$router.push(`/order-success?orderNumber=${orderNumber}`);
          // Redirect to success page
        }
      } catch (error) {
        console.error("Error processing payment:", error);
        alert("An error occurred. Please try again.");
      } finally {
        this.isProcessing = false;
      }
    },
  },
};
</script>


<style scoped>
ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
  padding: 10px;
  border-bottom: 1px solid #ddd;
}

input {
}

.dots::after {
  content: '';
  display: inline-block;
  animation: dots-animation 1.5s infinite;
}

@keyframes dots-animation {
  0% {
    content: '.';
  }
  33% {
    content: '..';
  }
  66% {
    content: '...';
  }
  100% {
    content: '';
  }
}


</style>