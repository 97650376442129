<template>
  <section class="" id="locations">
    <div class="container p-8 mx-auto mt-36">
      <h1 class="text-5xl text-brown-700 font-bold">Find Us At A Location Near You!</h1>

      <!-- setup a row with static image of google maps for location. -->

      <div class="container mx-auto mt-28">

        <div class="row mb-28">
          <div class="">
            <h1 class="text-center text-4xl text-black font-bold heading-text">Latinos
              Market</h1>
            <p class="text-gray-500 text-xl text-center p-2" style="font-family: 'Charter';">
              1535
              Ave G,
              Council Bluffs, IA
              51501</p>
          </div>

          <div class="lg:flex items-center justify-center lg:space-x-32 mt-10 space-y-6">
            <div class="">
              <h1 class="text-2xl font-bold text-black text-center">Hours Of Operation:</h1>
              <div class="text-center sm:text-center lg:text-left lg:pl-4 font-bold ml-auto">
                <p class="text text-black">Monday: 9am - 9pm</p>
                <p class="text text-black">Tuesday: 9am - 9pm</p>
                <p class="text text-black">Wednesday: 9am - 9pm</p>
                <p class="text text-black">Thursday: 9am - 9pm</p>
                <p class="text text-black">Friday: 9am - 9pm</p>
                <p class="text text-black">Saturday: 9am - 9pm</p>
                <p class="text text-black">Sunday: 9am - 9pm</p>
              </div>
            </div>

            <div class="">
              <a
                  href="https://www.google.com/maps/place/1535+Ave+G,+Council+Bluffs,+IA+51501"
                  target="_blank"
                  rel="noopener noreferrer"
              >
                <img
                    src="https://maps.googleapis.com/maps/api/staticmap?center=1535+Ave+G,Council+Bluffs,IA,51501&zoom=15&size=600x300&maptype=roadmap&markers=color:red%7C1535+Ave+G,Council+Bluffs,IA,51501&key=AIzaSyB8-4pxn4Yf1IKB8tACgo9An8yb7x-fTYY"
                    alt="Map showing 1535 Ave G, Council Bluffs, IA 51501"
                    class="rounded-md shadow-lg"
                />
              </a>
            </div>

          </div>


          <div class="flex justify-center mt-20">
            <div class="border-t-2 border-brown-600 w-3/4"></div>
          </div>

        </div>

        <div class="row mb-28">
          <div class="">
            <h1 class="text-center text-4xl text-black font-bold heading-text">Abarrotes La
              Ceiba</h1>
            <p class="text-gray-500 text-xl text-center p-2" style="font-family: 'Charter';">
              2308
              S 16th St, Omaha, NE 68108</p>
          </div>

          <div class="lg:flex items-center justify-center lg:space-x-32 mt-10 space-y-6">
            <div class="">
              <h1 class="text-2xl font-bold text-black text-center">Hours Of Operation:</h1>
              <div class="text-center sm:text-center lg:text-left lg:pl-4 font-bold ml-auto">
                <p class="text text-black">Monday: 8am - 9pm</p>
                <p class="text text-black">Tuesday: 8am - 9pm</p>
                <p class="text text-black">Wednesday: 8am - 9pm</p>
                <p class="text text-black">Thursday: 8am - 9pm</p>
                <p class="text text-black">Friday: 8am - 9pm</p>
                <p class="text text-black">Saturday: 8am - 9pm</p>
                <p class="text text-black">Sunday: 8am - 9pm</p>
              </div>
            </div>

            <div class="">
              <!-- 1. 2308 S 16th St, Omaha, NE 68108 -->
              <a
                  href="https://www.google.com/maps/place/2308+S+16th+St,+Omaha,+NE+68108"
                  target="_blank"
                  rel="noopener noreferrer"
              >
                <img
                    src="https://maps.googleapis.com/maps/api/staticmap?center=2308+S+16th+St,Omaha,NE,68108&zoom=15&size=600x300&maptype=roadmap&markers=color:red%7C2308+S+16th+St,Omaha,NE,68108&key=AIzaSyB8-4pxn4Yf1IKB8tACgo9An8yb7x-fTYY"
                    alt="Map showing 2308 S 16th St, Omaha, NE 68108"
                    class="rounded-md shadow-lg"
                />
              </a>

            </div>

          </div>

          <div class="flex justify-center mt-20">
            <div class="border-t-2 border-brown-600 w-3/4"></div>
          </div>

        </div>

        <div class="row mb-28">
          <div class="">
            <h1 class="text-center text-4xl text-black font-bold heading-text">Las Nenas
              Zamora</h1>
            <p class="text-gray-500 text-xl text-center p-2" style="font-family: 'Charter';">
              4907
              S 24th St, Omaha, NE 68107</p>
          </div>

          <div class="lg:flex items-center justify-center lg:space-x-32 mt-10 space-y-6">
            <div class="">
              <h1 class="text-2xl font-bold text-black text-center">Hours Of Operation:</h1>
              <div class="text-center sm:text-center lg:text-left lg:pl-4 font-bold ml-auto">
                <p class="text text-black">Monday: 8am - 8pm</p>
                <p class="text text-black">Tuesday: 8am - 8pm</p>
                <p class="text text-black">Wednesday: 8am - 8pm</p>
                <p class="text text-black">Thursday: 8am - 8pm</p>
                <p class="text text-black">Friday: 8am - 8pm</p>
                <p class="text text-black">Saturday: 8am - 8pm</p>
                <p class="text text-black">Sunday: 8am - 8pm</p>
              </div>
            </div>

            <div class="">
              <!-- 2. 4907 S 24th St, Omaha, NE 68107 -->
              <a
                  href="https://www.google.com/maps/place/4907+S+24th+St,+Omaha,+NE+68107"
                  target="_blank"
                  rel="noopener noreferrer"
              >
                <img
                    src="https://maps.googleapis.com/maps/api/staticmap?center=4907+S+24th+St,Omaha,NE,68107&zoom=15&size=600x300&maptype=roadmap&markers=color:red%7C4907+S+24th+St,Omaha,NE,68107&key=AIzaSyB8-4pxn4Yf1IKB8tACgo9An8yb7x-fTYY"
                    alt="Map showing 4907 S 24th St, Omaha, NE 68107"
                    class="rounded-md shadow-lg"
                />
              </a>
            </div>

          </div>

          <div class="flex justify-center mt-20">
            <div class="border-t-2 border-brown-600 w-3/4"></div>
          </div>

        </div>

        <div class="row mb-28">
          <div class="">
            <h1 class="text-center text-4xl text-black font-bold heading-text">El Mexicano
              24</h1>
            <p class="text-gray-500 text-xl text-center p-2" style="font-family: 'Charter';">
              4922
              S 24th St, Omaha, NE 68107</p>
          </div>

          <div class="lg:flex items-center justify-center lg:space-x-32 mt-10 space-y-6">
            <div class="">
              <h1 class="text-2xl font-bold text-black text-center">Hours Of Operation:</h1>
              <div class="text-center sm:text-center lg:text-left lg:pl-4 font-bold ml-auto">
                <p class="text text-black">Monday: 7am - 10pm</p>
                <p class="text text-black">Tuesday: 7am - 10pm</p>
                <p class="text text-black">Wednesday: 7am - 10pm</p>
                <p class="text text-black">Thursday: 7am - 10pm</p>
                <p class="text text-black">Friday: 7am - 10pm</p>
                <p class="text text-black">Saturday: 7am - 10pm</p>
                <p class="text text-black">Sunday: 7am - 10pm</p>
              </div>
            </div>

            <div class="">
              <!-- 3. 4922 S 24th St, Omaha, NE 68107 -->
              <a
                  href="https://www.google.com/maps/place/4922+S+24th+St,+Omaha,+NE+68107"
                  target="_blank"
                  rel="noopener noreferrer"
              >
                <img
                    src="https://maps.googleapis.com/maps/api/staticmap?center=4922+S+24th+St,Omaha,NE,68107&zoom=15&size=600x300&maptype=roadmap&markers=color:red%7C4922+S+24th+St,Omaha,NE,68107&key=AIzaSyB8-4pxn4Yf1IKB8tACgo9An8yb7x-fTYY"
                    alt="Map showing 4922 S 24th St, Omaha, NE 68107"
                    class="rounded-md shadow-lg"
                />
              </a>
            </div>

          </div>

          <div class="flex justify-center mt-20">
            <div class="border-t-2 border-brown-600 w-3/4"></div>
          </div>
        </div>

        <div class="row mb-28">
          <div class="">
            <h1 class="text-center text-4xl text-black font-bold heading-text">Tienda El
              Chapin</h1>
            <p class="text-gray-500 text-xl text-center p-2" style="font-family: 'Charter';">
              2012
              Q St, Omaha, NE 68107</p>
          </div>
          <div class="lg:flex items-center justify-center lg:space-x-32 mt-10 space-y-6">
            <div class="">
              <h1 class="text-2xl font-bold text-black text-center">Hours Of Operation:</h1>
              <div class="text-center sm:text-center lg:text-left lg:pl-4 font-bold ml-auto">
                <p class="text text-black">Monday: 9am - 9pm</p>
                <p class="text text-black">Tuesday: 9am - 9pm</p>
                <p class="text text-black">Wednesday: 9am - 9pm</p>
                <p class="text text-black">Thursday: 9am - 9pm</p>
                <p class="text text-black">Friday: 9am - 9pm</p>
                <p class="text text-black">Saturday: 9am - 9pm</p>
                <p class="text text-black">Sunday: 9am - 9pm</p>
              </div>
            </div>

            <div class="">

              <a
                  href="https://www.google.com/maps/place/2012+Q+St,+Omaha,+NE+68107"
                  target="_blank"
                  rel="noopener noreferrer"
              >
                <img
                    src="https://maps.googleapis.com/maps/api/staticmap?center=2012+Q+St,Omaha,NE,68107&zoom=15&size=600x300&maptype=roadmap&markers=color:red%7C2012+Q+St,Omaha,NE,68107&key=AIzaSyB8-4pxn4Yf1IKB8tACgo9An8yb7x-fTYY"
                    alt="Map showing 2012 Q St, Omaha, NE 68107"
                    class="rounded-md shadow-lg"
                />
              </a>
            </div>

          </div>

          <div class="flex justify-center mt-20">
            <div class="border-t-2 border-brown-600 w-3/4"></div>
          </div>
        </div>

        <div class="row mb-28">
          <div class="">
            <h1 class="text-center text-4xl text-black font-bold heading-text">La Guera</h1>
            <p class="text-gray-500 text-xl text-center p-2" style="font-family: 'Charter';">
              4233
              S 24th St, Omaha, NE 68107</p>
          </div>
          <div class="lg:flex items-center justify-center lg:space-x-32 mt-10 space-y-6">
            <div class="">
              <h1 class="text-2xl font-bold text-black text-center">Hours Of Operation:</h1>
              <div class="text-center sm:text-center lg:text-left lg:pl-4 font-bold ml-auto">
                <p class="text text-black">Monday: 8:30am - 8pm</p>
                <p class="text text-black">Tuesday: 8:30am - 8pm</p>
                <p class="text text-black">Wednesday: 8:30am - 8pm</p>
                <p class="text text-black">Thursday: 8:30am - 8pm</p>
                <p class="text text-black">Friday: 8:30am - 8pm</p>
                <p class="text text-black">Saturday: 8:30am - 8pm</p>
                <p class="text text-black">Sunday: 8:30am - 8pm</p>
              </div>
            </div>

            <div class="">
              <!-- 5. 4233 S 24th St, Omaha, NE 68107 -->
              <a
                  href="https://www.google.com/maps/place/4233+S+24th+St,+Omaha,+NE+68107"
                  target="_blank"
                  rel="noopener noreferrer"
              >
                <img
                    src="https://maps.googleapis.com/maps/api/staticmap?center=4233+S+24th+St,Omaha,NE,68107&zoom=15&size=600x300&maptype=roadmap&markers=color:red%7C4233+S+24th+St,Omaha,NE,68107&key=AIzaSyB8-4pxn4Yf1IKB8tACgo9An8yb7x-fTYY"
                    alt="Map showing 4233 S 24th St, Omaha, NE 68107"
                    class="rounded-md shadow-lg"
                />
              </a>
            </div>

          </div>

          <div class="flex justify-center mt-20">
            <div class="border-t-2 border-brown-600 w-3/4"></div>
          </div>
        </div>

        <div class="row mb-28">
          <div class="">
            <h1 class="text-center text-4xl text-black font-bold heading-text">El Caribe</h1>
            <p class="text-gray-500 text-xl text-center p-2" style="font-family: 'Charter';">
              7711
              L St, Ralston, NE 68127</p>
          </div>
          <div class="lg:flex items-center justify-center lg:space-x-32 mt-10 space-y-6">
            <div class="">
              <h1 class="text-2xl font-bold text-black text-center">Hours Of Operation:</h1>
              <div class="text-center sm:text-center lg:text-left lg:pl-4 font-bold ml-auto">
                <p class="text text-black">Monday: 9am - 8pm</p>
                <p class="text text-black">Tuesday: 9am - 8pm</p>
                <p class="text text-black">Wednesday: 9am - 8pm</p>
                <p class="text text-black">Thursday: 9am - 8pm</p>
                <p class="text text-black">Friday: 9am - 8pm</p>
                <p class="text text-black">Saturday: 9am - 8pm</p>
                <p class="text text-black">Sunday: CLOSED</p>
              </div>
            </div>

            <div class="">
              <!-- 6. 7711 L St, Ralston, NE 68127 -->
              <a
                  href="https://www.google.com/maps/place/7711+L+St,+Ralston,+NE+68127"
                  target="_blank"
                  rel="noopener noreferrer"
              >
                <img
                    src="https://maps.googleapis.com/maps/api/staticmap?center=7711+L+St,Ralston,NE,68127&zoom=15&size=600x300&maptype=roadmap&markers=color:red%7C7711+L+St,Ralston,NE,68127&key=AIzaSyB8-4pxn4Yf1IKB8tACgo9An8yb7x-fTYY"
                    alt="Map showing 7711 L St, Ralston, NE 68127"
                    class="rounded-md shadow-lg"
                />
              </a>
            </div>

          </div>

          <div class="flex justify-center mt-20">
            <div class="border-t-2 border-brown-600 w-3/4"></div>
          </div>
        </div>

        <div class="row mb-28">
          <div class="">
            <h1 class="text-center text-4xl text-black font-bold heading-text">Los Aviles
              Latin Market</h1>
            <p class="text-gray-500 text-xl text-center p-2" style="font-family: 'Charter';">
              10841 Q St ste 113, Omaha, NE 68137</p>
          </div>
          <div class="lg:flex items-center justify-center lg:space-x-32 mt-10 space-y-6">
            <div class="">
              <h1 class="text-2xl font-bold text-black text-center">Hours Of Operation:</h1>
              <div class="text-center sm:text-center lg:text-left lg:pl-4 font-bold ml-auto">
                <p class="text text-black">Monday: CLOSED</p>
                <p class="text text-black">Tuesday: 9am - 8pm</p>
                <p class="text text-black">Wednesday: 9am - 8pm</p>
                <p class="text text-black">Thursday: 9am - 8pm</p>
                <p class="text text-black">Friday: 9am - 8pm</p>
                <p class="text text-black">Saturday: 9am - 8pm</p>
                <p class="text text-black">Sunday: 9am - 6pm</p>
              </div>
            </div>

            <div class="">
              <!-- 7. 10841 Q St ste 113, Omaha, NE 68137 -->
              <a
                  href="https://www.google.com/maps/place/10841+Q+St+ste+113,+Omaha,+NE+68137"
                  target="_blank"
                  rel="noopener noreferrer">
                <img
                    src="https://maps.googleapis.com/maps/api/staticmap?center=10841+Q+St+ste+113,Omaha,NE,68137&zoom=15&size=600x300&maptype=roadmap&markers=color:red%7C10841+Q+St+ste+113,Omaha,NE,68137&key=AIzaSyB8-4pxn4Yf1IKB8tACgo9An8yb7x-fTYY"
                    alt="Map showing 10841 Q St ste 113, Omaha, NE 68137"
                    class="rounded-md shadow-lg"/>
              </a>

            </div>
          </div>

          <div class="flex justify-center mt-20">
            <div class="border-t-2 border-brown-600 w-3/4"></div>
          </div>
        </div>

        <div class="row mb-28">
          <div class="">
            <h1 class="text-center text-4xl text-black font-bold heading-text">Coffee
              Alley</h1>
            <p class="text-gray-500 text-xl text-center p-2" style="font-family: 'Charter';">
              7310
              Harrison St, Omaha, NE 68127</p>
          </div>
          <div class="lg:flex items-center justify-center lg:space-x-32 mt-10 space-y-6">
            <div class="">
              <h1 class="text-2xl font-bold text-black text-center">Hours Of Operation:</h1>
              <div class="text-center sm:text-center lg:text-left lg:pl-4 font-bold ml-auto">
                <p class="text text-black">Monday: 7am - 8pm</p>
                <p class="text text-black">Tuesday: 7am - 8pm</p>
                <p class="text text-black">Wednesday: 7am - 8pm</p>
                <p class="text text-black">Thursday: 7am - 8pm</p>
                <p class="text text-black">Friday: 7am - 5pm</p>
                <p class="text text-black">Saturday: 7am - 5pm</p>
                <p class="text text-black">Sunday: 8am - 5pm</p>
              </div>
            </div>

            <div class="">
              <!-- 8. 7310 Harrison St, Omaha, NE 68127 -->
              <a
                  href="https://www.google.com/maps/place/7310+Harrison+St,+Omaha,+NE+68127"
                  target="_blank"
                  rel="noopener noreferrer"
              >
                <img
                    src="https://maps.googleapis.com/maps/api/staticmap?center=7310+Harrison+St,Omaha,NE,68127&zoom=15&size=600x300&maptype=roadmap&markers=color:red%7C7310+Harrison+St,Omaha,NE,68127&key=AIzaSyB8-4pxn4Yf1IKB8tACgo9An8yb7x-fTYY"
                    alt="Map showing 7310 Harrison St, Omaha, NE 68127"
                    class="rounded-md shadow-lg"
                />
              </a>

            </div>

          </div>

          <div class="flex justify-center mt-20">
            <div class="border-t-2 border-brown-600 w-3/4"></div>
          </div>
        </div>

        <div class="row mb-28">
          <div class="">
            <h1 class="text-center text-4xl text-black font-bold heading-text">El Huarache
              Mexican Restaurant</h1>
            <p class="text-gray-500 text-xl text-center p-2" style="font-family: 'Charter';">
              4826
              Q St, Omaha, NE 68117</p>
          </div>
          <div class="lg:flex items-center justify-center lg:space-x-32 mt-10 space-y-6">
            <div class="">
              <h1 class="text-2xl font-bold text-black text-center">Hours Of Operation:</h1>
              <div class="text-center sm:text-center lg:text-left lg:pl-4 font-bold ml-auto">
                <p class="text text-black">Monday: 9am - 9pm</p>
                <p class="text text-black">Tuesday: 9am - 9pm</p>
                <p class="text text-black">Wednesday: 9am - 9pm</p>
                <p class="text text-black">Thursday: 9am - 9pm</p>
                <p class="text text-black">Friday: 9am - 10pm</p>
                <p class="text text-black">Saturday: 9am - 10pm</p>
                <p class="text text-black">Sunday: 9am - 9pm</p>
              </div>
            </div>

            <div class="">
              <!-- 9. 4826 Q St, Omaha, NE 68117 -->
              <a
                  href="https://www.google.com/maps/place/4826+Q+St,+Omaha,+NE+68117"
                  target="_blank"
                  rel="noopener noreferrer"
              >
                <img
                    src="https://maps.googleapis.com/maps/api/staticmap?center=4826+Q+St,Omaha,NE,68117&zoom=15&size=600x300&maptype=roadmap&markers=color:red%7C4826+Q+St,Omaha,NE,68117&key=AIzaSyB8-4pxn4Yf1IKB8tACgo9An8yb7x-fTYY"
                    alt="Map showing 4826 Q St, Omaha, NE 68117"
                    class="rounded-md shadow-lg"
                />
              </a>
            </div>

          </div>

          <div class="flex justify-center mt-20">
            <div class="border-t-2 border-brown-600 w-3/4"></div>
          </div>
        </div>

      </div>

    </div>

  </section>

  <section class="" id="social_media mt-0">

    <div class="container mx-auto p-6">
      <div class="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-4">
        <!-- Image 1 (large), spans 2 columns and 2 rows on large screens -->
        <div class="lg:col-span-2 lg:row-span-2 overflow-hidden relative group">
          <img
              src="/images/findUs/latinos.jpeg"
              alt="Social Media 1"
              class="w-full h-full object-cover transition-transform duration-300 group-hover:scale-105 scroll-animate"
          />
        </div>
        <!-- Image 2 -->
        <div class="overflow-hidden relative group">
          <img
              src="/images/findUs/findUs1.jpeg"
              alt="Social Media 2"
              class="w-full h-full object-cover transition-transform duration-300 group-hover:scale-105 scroll-animate"
          />
        </div>
        <!-- Image 3 -->
        <div class="overflow-hidden relative group">
          <img
              src="/images/findUs/findUs2.jpeg"
              alt="Social Media 3"
              class="w-full h-full object-cover transition-transform duration-300 group-hover:scale-105 scroll-animate"
          />
        </div>
        <!-- Image 4 -->
        <div class="overflow-hidden relative group">
          <img
              src="/images/findUs/findUs3.jpeg"
              alt="Social Media 4"
              class="w-full h-full object-cover transition-transform duration-300 group-hover:scale-105 scroll-animate"
          />
        </div>
        <!-- Image 5 -->
        <div class="overflow-hidden relative group">
          <img
              src="/images/findUs/findUs4.jpeg"
              alt="Social Media 5"
              class="w-full h-full object-cover transition-transform duration-300 group-hover:scale-105 scroll-animate"
          />
        </div>
        <!-- Image 6 -->
        <div class="overflow-hidden relative group row-span-1">
          <img
              src="/images/findUs/findUs5.jpeg"
              alt="Social Media 6"
              class="w-full h-full object-cover transition-transform duration-300 group-hover:scale-105 scroll-animate"
          />
        </div>
        <!-- Image 7 -->
        <div class="overflow-hidden relative group row-span-1">
          <img
              src="/images/findUs/findUs6.jpeg"
              alt="Social Media 7"
              class="w-full h-full object-cover transition-transform duration-300 group-hover:scale-105 scroll-animate"
          />
        </div>

      </div>
    </div>

  </section>


</template>

<script>
export default {
  name: "FindUs",
};
</script>