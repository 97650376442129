<template>
  <nav :class="['fixed top-0 w-full z-50 transition-colors', { scrolled: isScrolled }]"
       id="navigation">
    <div class="container mx-auto flex justify-between items-center h-32 px-2 py-1">
      <!-- Navbar content here -->
      <div class="flex items-center">
        <router-link to="/cart" class="flex items-center">
          <!-- Basket Icon with Shake Animation -->
          <div :class="{ 'shake-animation': isShaking }">
            <img src="/icons/basket.png" alt="Basket" class="h-12 w-12 object-contain"/>
          </div>

          <!-- Cart Count -->
          <span
              v-if="cartItemCount > 0"
              class="text-lg inline-flex items-center justify-center w-8 h-8 bg-blue-400 rounded-full text-white font-bold -mt-8"
          >
    {{ cartItemCount }}
  </span>
        </router-link>
        <div class="hidden lg:flex space-x-12 ml-4">
          <router-link to="/"
                       class="text-xl font-medium text-gray-500 hover:text-gray-300 ml-4 mr-4 nav-link">
            Home
          </router-link>
          <router-link to="/about"
                       class="text-xl font-medium text-white hover:text-gray-300 ml-4 mr-4 nav-link">
            About
          </router-link>
        </div>
      </div>
      <!-- Centered Logo -->
      <router-link to="/" class="absolute left-1/2 transform -translate-x-1/2">
        <img src="/icons/logo.png" alt="Logo" class="h-36 w-auto inline-block"/>
      </router-link>
      <!-- Right Side Links -->
      <div class="hidden lg:flex items-center space-x-12">
        <button @click.prevent="toggleSearch" class="search-icon">
          <img src="/icons/search.png" width="30" height="30" alt="Search Icon"/>
        </button>


        <router-link to="/products"
                     class="text-xl font-medium text-white hover:text-gray-300 nav-link">
          Products
        </router-link>

        <router-link to="/findUs"
                     class="text-xl font-medium text-white hover:text-gray-300 ml-8 mr-8 nav-link flex items-center">
          <span>Find Us</span>
          <img src="/icons/location.png" width="30" height="30">
        </router-link>

      </div>
      <!-- Mobile menu -->
      <div class="flex lg:hidden items-center ml-auto pr-4 space-x-6">
        <button @click.prevent="toggleSearch" class="search-icon">
          <img src="/icons/search.png" width="30" height="30" alt="Search Icon"/>
        </button>

        <button @click="toggleMobileMenu" class="text-4xl text-white focus:outline-none">
          {{ menuIcon }}
        </button>
      </div>
    </div>

    <transition name="dropdown">

      <div v-show="isSearchOpen" :class="{ 'visible': isSearchOpen }"
           class="dropdown-search-overlay space-x-0" @click.self="closeSearch">
        <div class="search-content pt-8 pl-4">

          <div class="flex items-center">
            <h1
                class="text-3xl sm:text-3xl md:text-4xl lg:text-5xl text-brown-700 p-2 font-bold"
                style="font-family: 'Lora', serif;">Search
              Our
              Catalog</h1>

            <button class="close-search font-gray text-3xl ml-auto mr-6" @click="closeSearch">x
            </button>
          </div>
          <input type="text" style="font-family: 'Lora', serif;"
                 placeholder="What are you craving today? (e.g. chocolate croissant)"
                 class="search-input"
                 @keyup.enter="handleSearch"
                 ref="searchInput"
                 v-model="searchQuery"/>

        </div>
      </div>
    </transition>

    <!-- Full-Screen Mobile Menu with Staggered Links -->
    <transition name="fade">

      <div
          v-if="isMobileMenuOpen"
          class="fixed top-0 left-0 w-full h-full bg-[#8B4513] text-white flex flex-col justify-center items-center z-40"
      >
        <button @click="closeMobileMenu" class="absolute top-4 right-4 text-3xl text-white">✕
        </button>
        <div class="flex flex-col items-center mt-8 space-y-6"
             style="font-family: 'Lora', cursive; font-weight: bold;">
          <router-link
              v-for="(link, index) in menuLinks"
              :key="link.path"
              :to="link.path"
              class="text-3xl font-medium hover:text-gray-300"
              @click="closeMobileMenu"
              :style="{
              opacity: linkVisible[index] ? 1 : 0,
              transform: linkVisible[index] ? 'translateY(0)' : 'translateY(20px)',
              transition: 'opacity 0.5s ease, transform 0.5s ease',
              transitionDelay: `${index * 0.1}s`
            }"
          >
            {{ link.text }}
          </router-link>
        </div>
      </div>
    </transition>
  </nav>
</template>

<script>
export default {
  name: 'MainNavbar',
  data() {
    return {
      cartTotalItems: 0,
      isSearchOpen: false,
      searchQuery: '',
      isMobileMenuOpen: false,
      isScrolled: false,
      menuLinks: [
        {text: 'Home', path: '/'},
        {text: 'About', path: '/about'},
        {text: 'Products', path: '/products'},
        {text: 'Find Us', path: '/findUs'},
      ],
      linkVisible: [], // Control visibility of links for staggered animation
    };
  },
  computed: {
    menuIcon() {
      return this.isMobileMenuOpen ? '✕' : '☰';
    },

    // Dynamically retrieve the cart count from Vuex
    cartItemCount() {
      return this.$store.getters.cartItemCount; // Vuex getter for total items in cart
    },
    // Retrieve the shake animation state from Vuex
    isShaking() {
      return this.$store.state.isBasketShaking; // Vuex state for basket animation
    },
  },
  mounted() {

    window.addEventListener('scroll', this.handleScroll);
    this.focusSearch();
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {

    handleSearch() {
      if (this.searchQuery) {
        //navigate to the search results page and pass the query as a url parameter.
        this.$router.push({name: 'SearchResults', query: {q: this.searchQuery}});
        this.isSearchOpen = false;
        this.searchQuery = '';

      }
    },

    toggleSearch() {
      this.isSearchOpen = !this.isSearchOpen;
      this.$nextTick(() => {       // Wait for the DOM to update
        this.focusSearch();        // Then set focus
      });

    },

    closeSearch() {
      this.isSearchOpen = false;
      this.searchQuery = '';
    },

    toggleMobileMenu() {
      this.isMobileMenuOpen = !this.isMobileMenuOpen;
      if (this.isMobileMenuOpen) {
        this.animateLinks();
      } else {
        this.resetLinkVisibility();
      }
    },
    closeMobileMenu() {
      this.isMobileMenuOpen = false;
      this.resetLinkVisibility();
    },
    animateLinks() {
      this.linkVisible = new Array(this.menuLinks.length).fill(false);
      this.menuLinks.forEach((_, index) => {
        setTimeout(() => {
          this.linkVisible[index] = true;
        }, index * 100); // Delay each link for stagger effect
      });
    },
    resetLinkVisibility() {
      this.linkVisible = [];
    },
    handleScroll() {
      this.isScrolled = window.scrollY > 150;
    },

    focusSearch() {
      this.$refs.searchInput.focus(); //set focus to input field
    },
  },


};
</script>

<style scoped>
/* Navbar scroll transition */
#navigation {
  transition: background-color 1.5s ease;
}

#navigation.scrolled {
  background-color: #8B4513;
}

/* Fade transition for full-screen menu */
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}

.nav-link {
  font-family: 'Lora', cursive;
  font-weight: bolder;
  color: white;
}

/* Dropdown Search Overlay */
.dropdown-search-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  transform: translateY(-100%); /* Initially hidden above the viewport */
  transition: transform 0.6s ease-in-out; /* Smooth slide-in effect */
  z-index: 1000;
  padding: 0;
  margin: 0;
}

/* When isSearchOpen is true, slide down the overlay */
.dropdown-search-overlay.visible {
  transform: translateY(0); /* Fully visible */
}

/* Content within the Overlay */
/* Container Style */
.search-content {
  width: 100%;
  height: 25vh;
  background-color: hsla(0, 0%, 100%, 0.999); /* White with 80% opacity */
}

/* Search Input Style */
.search-input {
  margin-top: 20px;
  width: 100%;
  padding: 15px;
  font-size: 1.2em;
  outline: none;
  margin-bottom: 20px;
  background-color: hsla(0, 0%, 100%, 0.89) !important; /* White with 80% opacity */
  border: none !important; /* Removes any border */
  box-shadow: none !important; /* Removes any box shadow */
  appearance: none; /* Removes default styles in certain browsers */
  -webkit-appearance: none; /* Ensures compatibility with Webkit-based browsers */
  -moz-appearance: none; /* Ensures compatibility with Firefox */
  border-radius: 0; /* Removes any rounding Tailwind might add */
}


/* Close Button */
.close-btn {
  padding: 8px 16px;
  font-size: 1em;
  color: white;
  background-color: #333;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.close-btn:hover {
  background-color: #555;
}

@keyframes shake {
  0%, 100% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(-5px);
  }
  50% {
    transform: translateX(5px);
  }
  75% {
    transform: translateX(-5px);
  }
}

.shake-animation {
  animation: shake 1s ease-in-out;
}
</style>