<template>
  <div>
    <!-- Card -->
    <div
        class="product-card mt-44"
        @click="openAnimatedModal"
        :class="{ animateCard: isAnimating }"
    >
      <h3>Sample Product</h3>
    </div>

    <!-- Modal with Transition -->
    <transition name="flip-scale">
      <div v-show="isModalOpen" class="modal-overlay" @click.self="closeModal">
        <div class="modal-content">
          <h2>Sample Product</h2>
          <p>This is a sample product description.</p>
          <button @click="closeModal">Close</button>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isModalOpen: false,
      isAnimating: false,
    };
  },
  methods: {
    openAnimatedModal() {
      this.isAnimating = true;
      this.$nextTick(() => {
        setTimeout(() => {
          this.isModalOpen = true;
          this.isAnimating = false;
        }, 500); // Duration of the animation
      });
    },
    closeModal() {
      this.isModalOpen = false;
    },
  },
};
</script>

<style scoped>
/* Card Style */
.product-card {
  width: 150px;
  height: 200px;
  background: #f3f3f3;
  border-radius: 8px;
  overflow: hidden;
  transition: transform 0.5s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

/* Apply rotation and scaling to the card on click */
.animateCard {
  transform: rotateY(180deg) scale(1.5);
  transition: transform 0.5s ease;
}

/* Modal Overlay with Smooth Fade */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  opacity: 1;
  transition: opacity 0.3s ease; /* Smooth fade for overlay */
}

/* Make overlay fade out smoothly */
.flip-scale-leave-active .modal-overlay {
  opacity: 0; /* Fade out the overlay */
}

/* Modal Content */
.modal-content {
  background: white;
  padding: 30px;
  border-radius: 8px;
  width: 80%;
  max-width: 600px;
  text-align: center;
  transform: scale(0.7);
  transition: transform 0.5s ease, opacity 0.3s ease;
  opacity: 1; /* Ensure modal content also fades */
}

/* Scale-Rotate Transition */
.flip-scale-enter-active, .flip-scale-leave-active {
  transition: transform 0.5s ease, opacity 0.3s ease;
}

.flip-scale-enter {
  transform: scale(0.5) rotateY(0deg);
  opacity: 0; /* Start content faded out */
}

.flip-scale-enter-active {
  opacity: 1; /* Fade in content smoothly */
}

.flip-scale-leave-to {
  transform: scale(0.5) rotateY(180deg);
  opacity: 0; /* Fade out content smoothly */
}
</style>