<template>
  <div class="container mx-auto p-8 mt-24 items-center justify-center">

    <div class="m-12 w-full sm:w-full md:w-3/4 lg:w-1/2 mx-auto">

      <img src="/images/decorative/heading.jpg" class="w-full h-12 mx-auto rounded-t-md">

      <div class="container p-20 text-center bg-[#f5ebd2]">
        <h1 class="text-xl font-bold text-black">Thank you for your order.
Please allow up to 2 days for fulfillment. We will be in contact.
A confirmation e-mail will be sent your way. Here is your order number.</h1>
            <h1 class="text-xl mt-12 font-bold text-black">Your order number is:
              <strong>{{ orderNumber }}</strong></h1>

      </div>


      <img src="/images/decorative/heading.jpg" class="w-full h-12 mx-auto rounded-b-md">
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      orderNumber: null, // Stores the order number
    };
  },
  created() {
    // Extract the order number from the query string
    this.orderNumber = this.$route.query.orderNumber;

    if (!this.orderNumber) {
        this.$router.push("/"); // Redirect to home or show an error
      console.error("No order number found in the route.");
      // Optionally redirect to another page or show an error
    }
  },
};
</script>

<style scoped>

</style>