<template>
  <div class="container p-8 space-y-8 mt-32 mx-auto w-full">

    <div class="grid cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 mx-auto">

      <div class="container">

        <img src="/images/about/Image%2010-27-24%20at%201.06%E2%80%AFPM.jpeg"
             class="w-full object-cover rounded-md"
             alt="Main Image">

      </div>


      <div class="container mx-auto justify-center items-center mt-32 w-full p-8">

        <h1 class="title text-4xl text-center w-full text-black font-bold">Cultivated By
          Passion</h1>

        <p class="text text-lg font-bold text-black text-center p-8"
           style="font-family: 'Lora', serif;">Delicias
          del pan
          was
          founded
          in 2023 by
          Leidy & Orlando.
          Cultivated by passion, the products of Delicias Del Pan are crafted using
          only natural ingredients, staying true to their artisanal nature.</p>

      </div>

    </div>

  </div>
</template>

<script>
export default {
  name: "AboutPage",
};
</script>