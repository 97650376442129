import { createStore } from 'vuex';

const CART_KEY = 'cart'; // Key for localStorage

const index = createStore({
  state: {
    cart: [], // Array to hold cart items
    isBasketShaking: false, // State to control basket shake animation
  },
  mutations: {
    setCart(state, cart) {
      state.cart = cart; // Set cart state
    },
    addToCart(state, { product, quantity }) {
      const validQuantity = Math.max(1, quantity); // Ensure quantity is at least 1
      const existingProduct = state.cart.find((item) => item.id === product.id);
      if (existingProduct) {
        existingProduct.quantity += validQuantity; // Update quantity
      } else {
        state.cart.push({ ...product, quantity: validQuantity }); // Add new product
      }
    },
    updateCartQuantity(state, { productId, quantity }) {
      const product = state.cart.find((item) => item.id === productId);
      if (product) {
        product.quantity = Math.max(1, quantity); // Ensure quantity is at least 1
      }
    },
    removeFromCart(state, productId) {
      state.cart = state.cart.filter((item) => item.id !== productId); // Remove product
    },
    clearCart(state) {
      state.cart = []; // Clear all cart items
    },
    triggerBasketShake(state) {
      state.isBasketShaking = true; // Start the shake animation
      setTimeout(() => {
        state.isBasketShaking = false; // Reset the shake animation
      }, 1000); // Match the CSS animation duration
    },
  },
  actions: {
    initializeCart({ commit }) {
      const savedCart = localStorage.getItem(CART_KEY);
      if (savedCart) {
        const validatedCart = JSON.parse(savedCart).map((item) => ({
          ...item,
          quantity: Math.max(1, item.quantity), // Ensure quantity is at least 1
        }));
        commit('setCart', validatedCart); // Load and validate cart from localStorage
      }
    },
    addToCart({ commit, state }, { product, quantity }) {
      console.log('Vuex Action - Product:', product, 'Quantity:', quantity); // Log inputs

      if (!product || !product.id) {
        console.error('Invalid product passed to Vuex:', product); // Log error
        return;
      }

      const validQuantity = Math.max(1, quantity); // Ensure quantity is at least 1
      commit('addToCart', { product, quantity: validQuantity });
      commit('triggerBasketShake'); // Trigger the shake animation
      localStorage.setItem(CART_KEY, JSON.stringify(state.cart)); // Persist cart
    },
    updateCartQuantity({ commit, state }, { productId, quantity }) {
      if (quantity < 1) {
        console.error("Quantity cannot be less than 1."); // Log error
        return;
      }
      commit('updateCartQuantity', { productId, quantity });
      localStorage.setItem(CART_KEY, JSON.stringify(state.cart)); // Persist to localStorage
    },
    removeFromCart({ commit, state }, productId) {
      commit('removeFromCart', productId);
      localStorage.setItem(CART_KEY, JSON.stringify(state.cart));
    },
    clearCart({ commit }) {
      commit('clearCart');
      localStorage.removeItem(CART_KEY); // Remove cart from localStorage
    },
  },
  getters: {
    cartItemCount: (state) =>
      state.cart.reduce((total, item) => total + item.quantity, 0), // Total items
    cartTotal: (state) =>
      state.cart.reduce((total, item) => total + item.price * item.quantity, 0), // Total price
  },
});

export default index;